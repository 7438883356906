import React from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';

const useStyles = makeStyles(() => ({
  sectionAlternateNoPaddingTop: {
    '& .section-alternate__content': {
      paddingBottom: 0,
    },
  },
  dividerSection: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const ContributeSolutions = ({ themeMode }) => {
  const classes = useStyles();

  return (
    <div>
      <iframe
        class="airtable-embed airtable-dynamic-height"
        src="https://airtable.com/embed/shrrzZxyqKHI7gABJ?backgroundColor=cyan"
        frameborder="0"
        onmousewheel=""
        width="100%"
        height="1550"
        style={{ background: 'transparent', border: '1px solid #ccc' }}
      ></iframe>
    </div>
  );
};

export default ContributeSolutions;
