import React from 'react';
import ContributeSolutions from 'views/ContributeSolutions';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

const ContributeSolutionsPage = (): JSX.Element => {
	return <WithLayout component={ContributeSolutions} layout={Main} />;
};

export default ContributeSolutionsPage;
